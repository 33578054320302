.hat {
    
    background-color: #f5f5f5;
    img {
        width: 100%;
    }
    
}
.hat {
    color: #1e266d;
    
}
.dropdown {
    border: 1px rgb(219, 219, 219) solid !important;
    border-radius: 7px;
    background-color: white;
    p {
        color: #1e266d;
        font-size: 16px;
        padding: 20px ;
        margin: 0;
        
        &:hover {
            background-color: #1e266d;
            color: white;
        }
    }
    .logout {
        padding: 20px;
        margin: 0;
    }
    div {
        margin: 0;
    }
    .drop_btn {
        float: right;
    }
    
}
.login {
    margin: 0;
}
.reg_btn {
    margin-left: 10px;
}