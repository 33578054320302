.drawer {
  div {
    div {
      &::-webkit-scrollbar {
        width: 10px; /* ширина scrollbar */
      }
      &::-webkit-scrollbar-track {
        background: white;        /* цвет дорожки */
      }
      &::-webkit-scrollbar-thumb {
        background-color: #1e266d;    /* цвет плашки */
        border-radius: 20px;       /* закругления плашки */
        border: 1px solid white;  /* padding вокруг плашки */
      }
    }
  }
}
.row {
  padding: 20px 0;
  button {
    &:hover {
      scale: 1.1;
    }
  }
}