
.menu {
    padding: 20px 0;
    justify-content: center;
    a {
        font-size: 18px;
        font-weight: 600;
    }
}
@media (max-width: 415px) {
    .menu {
        
            
            span {
                margin-inline-start: 0px !important;
            }
            .icon {
            
                font-size: 20px !important;
                margin: 0 20px !important;
            }
            
            .label {
                display: none;
            }
        
        
    }
}