@import 'animate.css';
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,500;0,700;1,100&family=Montserrat:wght@300;400;500;600;700&family=Nunito:wght@300;400;500;600;700;800&display=swap');



body {
  margin: 0;
  // min-width: 1400px;
  color: #1e266d;
  font-family: 'Montserrat', sans-serif;
  
  // animation: spin 8s ease-out infinite;
}
body::-webkit-scrollbar {
  width: 0px; /* ширина scrollbar */
}

@keyframes spin {
  0% {
    
    rotate: 0deg;
  }
  25% {
    
    rotate: 90deg;
  }
  50% {
    rotate: 180deg;
  }
  75% {
    rotate: 270deg;
  }
  100% {
    rotate: 360deg;
  }
}