.deleteorg_btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    svg {
        width: 20px;
        height: 20px;
    }
}
.explist {
    margin-bottom: 20px;
    span {
        font-size: 16px;
    }
}
@media (max-width: 600px) {
    .save, .cancel, .edit, .collapse, .expand {
        font-size: 12px;
    }
  }
  
