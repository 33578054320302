.loader {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 200px auto 0 auto;
  div {
    left: 50%;
    position: absolute;
    border: 5px solid #1e266d;
    border-radius: 50%;
    animation: loader 1s ease-out infinite;
    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
  
}

@keyframes loader {
  0% {
    top: 75px;
    left: 75px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}
