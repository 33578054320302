
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,500;0,700;1,100&family=Montserrat:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.logform {
  padding: 20px;
  margin-top: 20px;
}
@media (max-width: 576px) {
  .other_entries {
    font-size: 14px;
  }
}


// Гугловская кнопка
.google_btn {
  width: 100%;
  height: 40px;
  border: none;
  box-shadow: 0 2px 0 #060e215e;
  background-color: rgb(245, 245, 245);
    transition: 0.2s;
    cursor: pointer;
    .google_icon {
      margin-right: 20px;
    }
    .btn_text {
      transform: translateY(-11px);
      font-size: 16px;
      // font-family: "Roboto";
      font-weight: 600;
    }
    @media (max-width: 576px) {
      .google_icon {
        height: 25px;
      }
      .btn_text {
        transform: translateY(-8px);
        font-size: 14px;
        font-weight: 700;
      }
    }
    &:hover {
      scale: 1.05;
    }
    &:active {
      background: white;
    }
  }
@media (max-width: 576px) {
    
  .google_btn {
    height: 32px;
  }
}