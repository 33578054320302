.clear_item {
    padding: 0 15px !important;
    width: auto !important;
    span {
        font-size: 12px;
        
    }
}
.logo {
    background: url(/assets/img/ldap_logo.png);
    width: 100px;
    div {
        position: absolute;
        border: 2px solid;
        // border-color: linear-gradient(to right, red, blue, green);
        border-radius: 50%;
        animation: loader 1.5s ease-out infinite;
        
        animation-delay: 3s;
        
    }      
    @keyframes loader {
    0% {
        top: 25px;
        left: 32.5px;
        width: 0;
        height: 0;
        opacity: 1;
        color: #1e266d;
    }
    100% {
        top: 2px;
        left: 2px;
        width: 60px;
        height: 60px;
        opacity: 0;
        color: white;
    }
    }
}
.privacy {
    background: linear-gradient(to right,  transparent, transparent),
    linear-gradient(to right, #1e266d3d,  #1e266d);
    background-size: 100% 1px, 56% 1px;
    background-position: 0% 100%, 0% 100%,;
    background-repeat: no-repeat;
    transition: background-size 400ms;
    color: #1e266d;
    &:hover {
        background-size: 0% 1px, 100% 1px;
        color: #1e266d;
    }
}
.cart_topic {
    font-size: 40px !important;
    margin: 50px 0 !important;
}